import styled from 'styled-components';

import { mediaQueryMobileMin } from 'styleUtils';

import { BreadCrumbsWrapper as BaseWrapper } from 'components/FooterStyles';

export const BreadCrumbsWrapper = styled(BaseWrapper)`
  padding: 16px;

  @media ${mediaQueryMobileMin} {
    padding: 24px 16px;
  }
`;

export default BreadCrumbsWrapper;
