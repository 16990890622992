import React from 'react';
import { useSelector } from 'react-redux';

import { getDefaultBreadcrumbs } from 'selectors';
import { useBreadcrumbs, useSelectorProps } from 'hooks';

import BreadCrumbs from 'components/BreadCrumbs';

function PageBreadCrumbs() {
  const selectorProps = useSelectorProps();

  const defaultBreadcrumbs = useSelector(state =>
    getDefaultBreadcrumbs(state, selectorProps)
  );

  const breadcrumbs = useBreadcrumbs(defaultBreadcrumbs);

  return breadcrumbs.length ? <BreadCrumbs breadcrumbs={breadcrumbs} /> : null;
}

export default PageBreadCrumbs;
