import React, { memo } from 'react';
import PropTypes from 'prop-types';

import * as icons from 'assets/icons';

const spriteFilename = `${process.env.APP_ASSETS_PUBLIC_PATH}sprite.${process.env.APP_VERSION}.svg`;

const Icon = memo(({ name, ...props }) => {
  const icon = icons[name];

  if (!icon) {
    return null;
  }

  return (
    <svg viewBox={icon.default.viewBox} {...props}>
      <use xlinkHref={`${spriteFilename}#${icon.default.id}`} />
    </svg>
  );
});

Icon.propTypes = {
  name: PropTypes.string,
};

Icon.defaultProps = {
  name: '',
};

export default Icon;
