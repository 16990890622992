import { useSelector } from 'react-redux';

import { getBreadcrumbs } from 'selectors';

import { useSelectorProps } from './useSelectorProps';

export const useBreadcrumbs = (defaultBreadcrumbs = []) => {
  const selectorProps = useSelectorProps();
  const { items: breadcrumbs } = useSelector(state =>
    getBreadcrumbs(state, selectorProps)
  );

  if (!breadcrumbs) return defaultBreadcrumbs;

  // breadcrumbs returned from API may not contain all items, because SEO engineer
  // is not entered all data yet. In such a case we need to use a default.
  if (defaultBreadcrumbs.length > breadcrumbs.length) {
    return breadcrumbs.concat(defaultBreadcrumbs.slice(breadcrumbs.length));
  }

  return breadcrumbs;
};

export default useBreadcrumbs;
