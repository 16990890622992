import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageHelmet = memo(
  ({ pageTitle, metaTitle, metaDescription, meta, ...props }) => {
    return (
      <Helmet
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: metaTitle,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          ...meta,
        ]}
        {...props}
      />
    );
  }
);

PageHelmet.propTypes = {
  pageTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

PageHelmet.defaultProps = {
  pageTitle: '',
  metaTitle: '',
  metaDescription: '',
  meta: [],
};

export default PageHelmet;
