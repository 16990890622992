import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

import { withPreparedLink } from 'hocs';

const Link = memo(({ preparedLink, innerRef, ...props }) => (
  <ReactRouterLink
    to={preparedLink}
    href={preparedLink}
    innerRef={innerRef}
    {...props}
  />
));

Link.propTypes = {
  preparedLink: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.objectOf(PropTypes.any),
    }),
    PropTypes.string,
  ]).isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Link.defaultProps = {
  innerRef: null,
};

export default withPreparedLink(Link);
