import styled from 'styled-components';

import {
  colorWhite,
  colorGreen400,
  colorBlack900,
  colorBlack500,
  fontFamilyRaleway,
  fontFamilyRoboto,
} from 'styleUtils';

export const Wrapper = styled.div`
  width: 100%;
  background: ${colorWhite};
  padding: 32px;
`;

export const IconWrapper = styled.div`
  width: 48px;
  margin: 0 auto 16px;
`;

export const Title = styled.h2`
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${colorBlack500};
`;

export const Description = styled.p`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${colorBlack900};
`;

export const Handler = styled.button`
  display: block;
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colorGreen400};
  border: none;
  background: transparent;
  margin: 16px auto;
  outline: none;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
