import styled from 'styled-components';

import { mediaQueryMobileMin, mediaQueryDesktopMin } from 'styleUtils';

const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -16px;
  padding: 16px 0;
  width: calc(100% + 32px);

  @media ${mediaQueryMobileMin} {
    margin: 0;
    width: 100%;
  }

  @media ${mediaQueryDesktopMin} {
    width: 1100px;
    padding: 24px 0;
  }
`;

export default Wrapper;
