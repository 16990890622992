import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Hint from 'components/Hint';

import {
  ProductActionsWrapper,
  ProductActionsList,
  ProductAction,
  FinawardsIcon,
  CheckIcon,
} from './styles';

const MAP_CODE_TO_ICON = {
  fa: 'original',
  ga: 'gold',
  sa: 'silver',
  ba: 'bronze',
};

const handleIconCode = title => {
  const iconCodeRegExp = / (fa|ga|sa|ba)$/;

  if (iconCodeRegExp.test(title)) {
    const code = title.match(iconCodeRegExp)[1];
    const titleWithoutCode = title.replace(iconCodeRegExp, '');

    return (
      <>
        {titleWithoutCode}
        <FinawardsIcon name={MAP_CODE_TO_ICON[code]} />
      </>
    );
  }

  return title;
};

function ProductActions({ advantages }) {
  return (
    <ProductActionsWrapper>
      <ProductActionsList>
        {advantages.map(advantage => (
          <ProductAction key={advantage.title}>
            <CheckIcon />

            {handleIconCode(advantage.title)}

            <Hint position="bottom">
              <span>{advantage.description}</span>
            </Hint>
          </ProductAction>
        ))}
      </ProductActionsList>
    </ProductActionsWrapper>
  );
}

ProductActions.propTypes = {
  advantages: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string }).isRequired
  ).isRequired,
};

export default memo(ProductActions);
