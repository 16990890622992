import styled from 'styled-components';

import { colorGreen400, fontFamilyRoboto } from 'styleUtils';

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: #696f6b;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 24px;
  text-align: left;
  outline: none;
  font-family: ${fontFamilyRoboto};
  width: fit-content;
  padding: 0 14px 0 0;
  position: relative;

  &::after {
    content: '';
    border-bottom: 2px solid #696f6b;
    border-right: 2px solid #696f6b;
    display: inline-flex;
    height: 7px;
    position: absolute;
    top: 8px;
    right: 0;
    transform: rotate(45deg);
    width: 7px;
  }

  &[data-active='true']::after {
    top: 10px;
    transform: rotate(-135deg);
    border-color: ${colorGreen400};
  }

  &[data-active='true'] {
    color: ${colorGreen400};
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

export default Button;
