import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { getLocaleUrlPath } from 'utils';
import { PRODUCT_TYPES, CLIENT_TYPES } from 'constants/index';

import { useSelectorProps } from './useSelectorProps';

const DEFAULT_OG_IMAGE_NAME = 'rko';

export const useMetaPage = () => {
  const { locale } = useIntl();
  const { location, match = {} } = useSelectorProps();
  const { params = {} } = match;
  const { clientType } = params;

  const isFopPage = params.page === PRODUCT_TYPES.fop;
  const isItPage = clientType === CLIENT_TYPES.it;

  const imageType =
    (isItPage && CLIENT_TYPES.it) ||
    (isFopPage && PRODUCT_TYPES.fop) ||
    DEFAULT_OG_IMAGE_NAME;

  const ogImageUrl = `${process.env.APP_DEPLOY_URL}${process.env.APP_ASSETS_PUBLIC_PATH}og-image-${locale}-${imageType}.png`;

  return useMemo(
    () => [
      {
        property: 'og:image',
        content: ogImageUrl,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: 'og:url',
        content: `https://minfin.com.ua${getLocaleUrlPath(locale)}${
          process.env.APP_ROUTER_BASENAME
        }${location.pathname}`,
      },
    ],
    [location.pathname, locale]
  );
};

export default useMetaPage;
