import styled from 'styled-components';

import Icon from 'components/Icon';
import BaseFinawardsIcon from 'components/FinawardsIcon';

import {
  mediaQueryLaptopMin,
  mediaQueryMobileLargeMin,
  primary400,
} from 'styleUtils';

export const ProductActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;

  @media ${mediaQueryMobileLargeMin} {
    margin: 0 0 8px;
  }
`;

export const ProductActionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProductAction = styled.span`
  align-items: center;
  color: #111512;
  display: inline-flex;
  font-size: 12px;
  line-height: 31px;
  height: 32px;

  &:not(:last-child) {
    margin-right: 12px;
  }

  @media ${mediaQueryLaptopMin} {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export const CheckIcon = styled(Icon).attrs({ name: 'check' })`
  fill: ${primary400};
  height: 16px;
  margin-right: 5px;
  width: 11px;
`;

export const FinawardsIcon = styled(BaseFinawardsIcon)`
  margin-left: 5px;
`;
