import styled from 'styled-components';

import ContentSection from 'components/ContentSection';

import { colorBlack300, fontFamilyRoboto } from 'styleUtils';

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
`;

export const Blocks = styled(ContentSection)`
  font-family: ${fontFamilyRoboto};
  font-size: 16px;
  line-height: 28px;

  color: ${colorBlack300};
`;

export const BlockTitle = styled.h3``;
