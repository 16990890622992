import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { makeGetSeoLinksBlocksItems } from 'selectors';

import SeoLinksBlock from 'components/SeoLinksBlock';

const getSeoLinksBlocksItems = makeGetSeoLinksBlocksItems();

function SeoLinksBlocks({ match, location }) {
  const intl = useIntl();

  const selectorProps = {
    intl,
    match,
    location,
  };

  const linksBlocksItems = useSelector(state =>
    getSeoLinksBlocksItems(state, selectorProps)
  );

  return linksBlocksItems.map(({ id, name, children }) => (
    <SeoLinksBlock key={id} title={name} links={children} />
  ));
}

SeoLinksBlocks.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default withRouter(SeoLinksBlocks);
