import React, { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';

import { getDimensions, closest } from 'utils';

import { Wrapper, Arrow } from './styles';

const TOP = 'top';
const BOTTOM = 'bottom';
const LEFT = 'left';
const RIGHT = 'right';

const POSITIONS_PRIORITIES = {
  top: [TOP, BOTTOM, LEFT, RIGHT],
  bottom: [BOTTOM, TOP, LEFT, RIGHT],
  left: [LEFT, RIGHT, TOP, BOTTOM],
  right: [RIGHT, LEFT, TOP, BOTTOM],
};

class Tooltip extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    position: null,
    top: 0,
    left: 0,
    arrowOffset: null,
  };

  tipRef = createRef();

  componentDidMount() {
    this.getTarget();
    this.setListeners();
    this.updateTooltip();
  }

  componentDidUpdate(prevProps) {
    const { show, target } = this.props;

    if (target && target !== prevProps.target) {
      this.getTarget();
    }

    if (show !== prevProps.show) {
      if (show) {
        this.setGlobalListeners();
      } else {
        this.removeGlobalListeners();
      }
    }

    this.updateTooltip();
  }

  componentWillUnmount() {
    this.removeListeners();
    this.removeGlobalListeners();
  }

  showTooltip = () => {
    const { toggle } = this.props;
    toggle(true);
  };

  hideTooltip = () => {
    const { toggle } = this.props;
    toggle(false);
  };

  setGlobalListeners = () => {
    const { hideOnClickOutside } = this.props;

    window.addEventListener('scroll', this.hideTooltip);

    if (hideOnClickOutside) {
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('touchend', this.handleClickOutside);
    }
  };

  removeGlobalListeners = () => {
    const { hideOnClickOutside } = this.props;

    window.removeEventListener('scroll', this.hideTooltip);

    if (hideOnClickOutside) {
      document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('touchend', this.handleClickOutside);
    }
  };

  setListeners = () => {
    const { showEvents, hideEvents } = this.props;

    showEvents.forEach(event => {
      this.target.addEventListener(event, this.showTooltip);
    });

    hideEvents.forEach(event => {
      this.target.addEventListener(event, this.hideTooltip);
    });
  };

  removeListeners = () => {
    const { showEvents, hideEvents } = this.props;

    showEvents.forEach(event => {
      this.target.removeEventListener(event, this.showTooltip);
    });

    hideEvents.forEach(event => {
      this.target.removeEventListener(event, this.hideTooltip);
    });
  };

  getTarget = () => {
    const { target } = this.props;

    this.target =
      document.getElementById(target) ||
      this.tipRef.current.parentNode.querySelector('[data-tip-target]') ||
      this.tipRef.current.parentNode;
  };

  updateTooltip = () => {
    const positionOptions = this.getPosition();

    this.setState({
      ...positionOptions,
    });
  };

  getDirection = position => {
    return position === TOP || position === BOTTOM ? 'vertical' : 'horizontal';
  };

  calculatePosition = (t, b, l, r, fullTipWidth, fullTipHeight, offset) => {
    const { position } = this.props;
    const { clientHeight, clientWidth } = document.documentElement;

    const positionsPosibility = {
      top: t - offset > 0,
      bottom: b + offset + fullTipHeight < clientHeight,
      left: l - offset > 0,
      right: r + offset + fullTipWidth < clientWidth,
    };

    const resultPosition = POSITIONS_PRIORITIES[position].find(
      p => positionsPosibility[p]
    );

    return resultPosition || position;
  };

  calculateOffset = (
    t,
    b,
    l,
    r,
    offset,
    position,
    vericalLeft,
    horizontalTop,
    tipWidth,
    tipHeight
  ) => {
    const { clientWidth, clientHeight } = document.documentElement;
    const direction = this.getDirection(position);
    const top = position === TOP ? t : b;
    const left = position === LEFT ? l : r;

    if (direction === 'vertical') {
      const leftOffset = vericalLeft - offset;
      const rightOffset = vericalLeft + tipWidth + offset;

      if (leftOffset < 0) {
        return {
          top,
          left: offset,
          arrowOffset: 50 - ((Math.abs(vericalLeft) + offset) * 100) / tipWidth,
        };
      }

      if (rightOffset > clientWidth) {
        return {
          top,
          left: clientWidth - tipWidth - offset,
          arrowOffset:
            50 + (Math.abs(rightOffset - clientWidth) * 100) / tipWidth,
        };
      }

      return {
        top,
        left: vericalLeft,
        arrowOffset: null,
      };
    }

    if (direction === 'horizontal') {
      const topOffset = horizontalTop - offset;
      const bottomOffset = horizontalTop + tipHeight + offset;

      if (topOffset < 0) {
        return {
          top: offset,
          left,
          arrowOffset:
            50 - ((Math.abs(horizontalTop) + offset) * 100) / tipHeight,
        };
      }

      if (bottomOffset > clientHeight) {
        return {
          top: clientHeight - tipHeight - offset,
          left,
          arrowOffset:
            50 + (Math.abs(bottomOffset - clientHeight) * 100) / tipHeight,
        };
      }

      return {
        top: horizontalTop,
        left,
        arrowOffset: null,
      };
    }

    return null;
  };

  getPosition = () => {
    const { hideArrow, offset: propOffset } = this.props;

    const triangleHeight = hideArrow ? 0 : 4;
    const margin = 4;
    const offset = propOffset || 10;

    const {
      top: targetTop,
      left: targetLeft,
      width: targetWidth,
      height: targetHeight,
    } = getDimensions(this.target);

    const { width: tipWidth, height: tipHeight } = getDimensions(
      this.tipRef.current
    );

    const t = targetTop - tipHeight - triangleHeight - margin;
    const b = targetTop + targetHeight + triangleHeight + margin;
    const l = targetLeft - tipWidth - triangleHeight - margin;
    const r = targetLeft + targetWidth + triangleHeight + margin;
    const vericalLeft = targetLeft + targetWidth / 2 - tipWidth / 2;
    const horizontalTop = targetTop + targetHeight / 2 - tipHeight / 2;
    const fullTipWidth = tipWidth + triangleHeight + margin;
    const fullTipHeight = tipHeight + triangleHeight + margin;

    const resultPosition = this.calculatePosition(
      t,
      b,
      l,
      r,
      fullTipWidth,
      fullTipHeight,
      offset
    );
    const calculatedOffset = this.calculateOffset(
      t,
      b,
      l,
      r,
      offset,
      resultPosition,
      vericalLeft,
      horizontalTop,
      tipWidth,
      tipHeight
    );

    return {
      position: resultPosition,
      ...calculatedOffset,
    };
  };

  handleClickOutside = event => {
    if (this.tipRef.current && !closest(event.target, this.target)) {
      this.hideTooltip();
    }
  };

  render() {
    const {
      children,
      hideArrow,
      style,
      show,
      fixedPosition,
      width,
      height,
    } = this.props;

    return (
      <Wrapper
        ref={this.tipRef}
        style={style}
        show={show}
        fixedPosition={fixedPosition}
        width={width}
        height={height}
        {...this.state}
      >
        {!hideArrow && <Arrow />}
        {show && children}
      </Wrapper>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  toggle: PropTypes.func,
  target: PropTypes.string,
  position: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  fixedPosition: PropTypes.bool,
  show: PropTypes.bool,
  hideArrow: PropTypes.bool,
  offset: PropTypes.number,
  showEvents: PropTypes.arrayOf(PropTypes.string),
  hideEvents: PropTypes.arrayOf(PropTypes.string),
  hideOnClickOutside: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

Tooltip.defaultProps = {
  children: null,
  position: RIGHT,
  target: '',
  toggle: () => {},
  style: {},
  fixedPosition: false,
  show: null,
  hideArrow: false,
  offset: 10,
  showEvents: [],
  hideEvents: [],
  hideOnClickOutside: true,
  width: null,
  height: null,
};

export default Tooltip;
