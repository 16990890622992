import styled from 'styled-components';

import { colorWhite, mediaQueryMobileMin } from 'styleUtils';

export const Footer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const BreadCrumbsWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: calc(100% + 32px);
  margin: 0 -16px;
  padding: 0 16px;
  background: ${colorWhite};

  @media ${mediaQueryMobileMin} {
    padding-right: 40px;
    padding-left: 40px;
  }
`;

export default Wrapper;
