import React from 'react';
import styled from 'styled-components';

import { mediaQueryDesktopMin, colorWhite } from 'styleUtils';

const Wrapper = styled(({ hideOnMobile, sticky, ...props }) => (
  <section {...props} />
))`
  background-color: ${colorWhite};
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.14);
  padding: 16px;
  margin: 0 0 16px 0;
  width: 100%;

  @media ${mediaQueryDesktopMin} {
    padding: 24px;
  }

  ${({ hideOnMobile }) => {
    if (hideOnMobile) {
      return `
        display: none;

        @media ${mediaQueryDesktopMin} {
          display: block;
        }
      `;
    }

    return '';
  }}

  ${({ sticky }) => {
    if (sticky) {
      return `
        position: sticky;
        top: 16px;
      `;
    }

    return '';
  }}
`;

export default Wrapper;
