import PropTypes from 'prop-types';

const iconNames = {
  // finawards original
  original: (
    <>
      <g clipPath="url(#prefix__a)">
        <path
          d="M12.526 23.957l-.542-.675V.658l.542-.616 10.86 11.96-2.514 2.636-2.894-3.035-2.413 2.53.033 5.684-3.072 4.14zm3.868-14.924l-1.092-1.145-.013 2.305 1.105-1.16z"
          fill="url(#prefix__b)"
          stroke="url(#prefix__c)"
          strokeWidth={0.032}
          strokeMiterlimit={10}
        />
        <path
          d="M12.526 23.957V.04L23.386 12l-2.397 2.635-2.757-3.035-2.297 2.53.03 5.684-3.439 4.143zm4.2-14.924l-1.042-1.145-.012 2.305 1.054-1.16z"
          fill="url(#prefix__d)"
          stroke="#DDE4E9"
          strokeWidth={0.031}
          strokeMiterlimit={10}
        />
        <g
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <g
            style={{
              mixBlendMode: 'multiply',
            }}
          >
            <path
              d="M23.384 11.998L12.526.04l-.542.618v22.624l.542.675 3.442-4.143-.03-5.685 2.171-2.391 2.763 2.897.061-.064.058.064 2.393-2.637zm-7.709-2.212l.008-1.498.709.745-.717.753z"
              fill="#CDD6DD"
            />
            <path
              d="M23.384 11.998L12.526.04l-.542.618v22.624l.542.675 3.442-4.143-.03-5.685 2.171-2.391 2.763 2.897.061-.064.058.064 2.393-2.637zm-7.709-2.212l.008-1.498.709.745-.717.753z"
              stroke="#DDE4E9"
              strokeWidth={0.031}
              strokeMiterlimit={10}
            />
          </g>
          <path
            d="M10.882.04L.48 12l10.36 11.958.5-.686v-5.385l-1.712-1.901 1.713-2.055V8.793l-.459-.658-3.969 4.876-1.002-1.104 5.43-6.163V.763L10.882.04z"
            fill="url(#prefix__e)"
            stroke="#D3BA85"
            strokeWidth={0.031}
            strokeMiterlimit={10}
          />
          <path
            d="M10.84.041L-.022 12l10.86 11.957v-6.07l-1.712-1.902 1.712-2.054V8.136L6.412 13.01l-1.003-1.103 5.43-6.164V.041z"
            fill="url(#prefix__f)"
            stroke="#D3BA85"
            strokeWidth={0.031}
            strokeMiterlimit={10}
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="prefix__b"
          x1={5242.77}
          y1={769.865}
          x2={5242.77}
          y2={14495.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4B5C2" />
          <stop offset={0.24} stopColor="#F4F4F4" />
          <stop offset={0.54} stopColor="#6E818E" />
          <stop offset={0.93} stopColor="#C3D8F0" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={11.966}
          y1={11.999}
          x2={23.406}
          y2={11.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3D8F0" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1={5065.39}
          y1={769.904}
          x2={5065.39}
          y2={14496.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF8F8" />
          <stop offset={0.24} stopColor="#DAF0F5" />
          <stop offset={0.54} stopColor="#F5FBFB" />
          <stop offset={0.88} stopColor="#EEF8F7" />
        </linearGradient>
        <linearGradient
          id="prefix__e"
          x1={5.911}
          y1={0.04}
          x2={5.911}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD9A6F" />
          <stop offset={0.18} stopColor="#917944" />
          <stop offset={0.47} stopColor="#E0C884" />
          <stop offset={0.59} stopColor="#372314" />
          <stop offset={0.85} stopColor="#B0965B" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__f"
          x1={5.409}
          y1={0.041}
          x2={5.409}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <clipPath id="prefix__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
  // finawards gold
  gold: (
    <>
      <path
        d="M10.923.04L.52 12l10.36 11.958.5-.686v-5.385l-1.71-1.902 1.712-2.055V8.793l-.458-.658-3.97 4.876-1.002-1.104 5.43-6.163V.763L10.923.04z"
        fill="url(#prefix__a)"
        stroke="#D3BA85"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <path
        d="M10.882.04L.022 12l10.86 11.958v-6.071l-1.713-1.901 1.713-2.055V8.135l-4.428 4.876-1.002-1.104 5.43-6.163V.04z"
        fill="url(#prefix__b)"
        stroke="#D3BA85"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <path
        d="M12.525 23.957l-.541-.675V.658l.541-.616 10.86 11.96-2.514 2.636-2.894-3.035-2.412 2.53.033 5.684-3.073 4.14zm3.868-14.924l-1.091-1.145-.014 2.305 1.105-1.16z"
        fill="url(#prefix__c)"
        stroke="#D3BA85"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <path
        d="M12.526 23.957V.04L23.386 12l-2.396 2.635-2.757-3.035-2.298 2.53.031 5.684-3.44 4.143zm4.2-14.924l-1.041-1.145-.013 2.305 1.055-1.16z"
        fill="url(#prefix__d)"
        stroke="#D3BA85"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <defs>
        <linearGradient
          id="prefix__a"
          x1={5.951}
          y1={0.04}
          x2={5.951}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD9A6F" />
          <stop offset={0.18} stopColor="#917944" />
          <stop offset={0.47} stopColor="#E0C884" />
          <stop offset={0.59} stopColor="#372314" />
          <stop offset={0.85} stopColor="#B0965B" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={5.452}
          y1={0.04}
          x2={5.452}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={5242.77}
          y1={769.865}
          x2={5242.77}
          y2={14495.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD9A6F" />
          <stop offset={0.18} stopColor="#917944" />
          <stop offset={0.47} stopColor="#E0C884" />
          <stop offset={0.59} stopColor="#372314" />
          <stop offset={0.85} stopColor="#B0965B" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1={5065.39}
          y1={769.904}
          x2={5065.39}
          y2={14496.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
      </defs>
    </>
  ),
  // finawards silver
  silver: (
    <>
      <path
        d="M10.922.04L.52 12l10.36 11.958.5-.686v-5.385l-1.712-1.901 1.713-2.055V8.793l-.459-.658-3.97 4.876-1.002-1.104 5.43-6.163V.763L10.922.04z"
        fill="url(#prefix__a)"
        stroke="#fff"
        strokeWidth={0.032}
        strokeMiterlimit={10}
      />
      <path
        d="M10.881.041L.021 12l10.86 11.957v-6.07L9.17 15.984l1.712-2.054V8.136L6.454 13.01 5.45 11.908l5.43-6.164V.041z"
        fill="url(#prefix__b)"
        stroke="#DDE4E9"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <path
        d="M12.525 23.957l-.542-.675V.658l.542-.616 10.86 11.96-2.514 2.636-2.894-3.035-2.413 2.53.033 5.684-3.072 4.14zm3.868-14.924L15.3 7.888l-.014 2.305 1.106-1.16z"
        fill="url(#prefix__c)"
        stroke="url(#prefix__d)"
        strokeWidth={0.032}
        strokeMiterlimit={10}
      />
      <path
        d="M12.525 23.957V.04L23.385 12l-2.397 2.635-2.757-3.035-2.297 2.53.03 5.684-3.44 4.143zm4.2-14.924l-1.042-1.145-.013 2.305 1.055-1.16z"
        fill="url(#prefix__e)"
        stroke="#DDE4E9"
        strokeWidth={0.031}
        strokeMiterlimit={10}
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
      >
        <g
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M10.881.09V.04L.021 12l10.86 11.957.5-.685v-5.386l-1.712-1.9 1.712-2.055V8.793l-.458-.657-.042.051v-.051l-4.177 4.6-.753-.829 5.43-6.163V.764l-.458-.723-.042.048z"
            fill="#CDD6DD"
          />
          <path
            d="M10.881.09V.04L.021 12l10.86 11.957.5-.685v-5.386l-1.712-1.9 1.712-2.055V8.793l-.458-.657-.042.051v-.051l-4.177 4.6-.753-.829 5.43-6.163V.764l-.458-.723-.042.048z"
            stroke="#DDE4E9"
            strokeWidth={0.031}
            strokeMiterlimit={10}
          />
        </g>
        <g
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <path
            d="M23.383 11.998L12.525.04l-.542.618v22.624l.542.675 3.442-4.143-.031-5.685 2.172-2.391 2.763 2.897.06-.064.06.064 2.392-2.637zm-7.71-2.212l.01-1.498.707.745-.716.753z"
            fill="#CDD6DD"
          />
          <path
            d="M23.383 11.998L12.525.04l-.542.618v22.624l.542.675 3.442-4.143-.031-5.685 2.172-2.391 2.763 2.897.06-.064.06.064 2.392-2.637zm-7.71-2.212l.01-1.498.707.745-.716.753z"
            stroke="#DDE4E9"
            strokeWidth={0.031}
            strokeMiterlimit={10}
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="prefix__a"
          x1={5.95}
          y1={0.04}
          x2={5.95}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4B5C2" />
          <stop offset={0.24} stopColor="#F4F4F4" />
          <stop offset={0.54} stopColor="#6E818E" />
          <stop offset={0.93} stopColor="#C3D8F0" />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1={5.451}
          y1={0.041}
          x2={5.451}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF8F8" />
          <stop offset={0.24} stopColor="#DAF0F5" />
          <stop offset={0.54} stopColor="#F5FBFB" />
          <stop offset={0.88} stopColor="#EEF8F7" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={5242.77}
          y1={769.865}
          x2={5242.77}
          y2={14495.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4B5C2" />
          <stop offset={0.24} stopColor="#F4F4F4" />
          <stop offset={0.54} stopColor="#6E818E" />
          <stop offset={0.93} stopColor="#C3D8F0" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1={11.965}
          y1={12}
          x2={23.405}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3D8F0" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__e"
          x1={5065.39}
          y1={769.904}
          x2={5065.39}
          y2={14496.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF8F8" />
          <stop offset={0.24} stopColor="#DAF0F5" />
          <stop offset={0.54} stopColor="#F5FBFB" />
          <stop offset={0.88} stopColor="#EEF8F7" />
        </linearGradient>
      </defs>
    </>
  ),
  // finawards bronze
  bronze: (
    <>
      <g clipPath="url(#prefix__a)">
        <path
          d="M10.902.04L.5 12l10.36 11.958.5-.686v-5.385l-1.712-1.901 1.713-2.055V8.793l-.459-.658-3.97 4.876-1.002-1.104 5.43-6.163V.763L10.902.04z"
          fill="url(#prefix__b)"
          stroke="#D3BA85"
          strokeWidth={0.031}
          strokeMiterlimit={10}
        />
        <path
          d="M10.86.041L0 12l10.86 11.958v-6.07l-1.712-1.902 1.712-2.054V8.136L6.433 13.01 5.43 11.907l5.43-6.163V.041z"
          fill="url(#prefix__c)"
          stroke="#D3BA85"
          strokeWidth={0.031}
          strokeMiterlimit={10}
        />
        <path
          d="M12.504 23.957l-.542-.675V.658l.542-.616 10.86 11.96-2.515 2.636-2.894-3.035-2.412 2.53.033 5.684-3.072 4.14zm3.868-14.924L15.28 7.888l-.014 2.305 1.106-1.16z"
          fill="url(#prefix__d)"
          stroke="#D3BA85"
          strokeWidth={0.031}
          strokeMiterlimit={10}
        />
        <path
          d="M12.503 23.957V.04L23.363 12l-2.396 2.635L18.21 11.6l-2.298 2.53.031 5.684-3.44 4.143zm4.2-14.924l-1.041-1.145-.013 2.305 1.054-1.16z"
          fill="url(#prefix__e)"
          stroke="#D3BA85"
          strokeWidth={0.031}
          strokeMiterlimit={10}
        />
        <g
          style={{
            mixBlendMode: 'multiply',
          }}
        >
          <g
            style={{
              mixBlendMode: 'multiply',
            }}
          >
            <path
              d="M10.86.089V.041L0 12l10.86 11.958.5-.685v-5.386l-1.712-1.9 1.712-2.055V8.793l-.458-.657-.042.05v-.05l-4.177 4.6-.753-.829 5.43-6.163V.764l-.458-.723-.042.048z"
              fill="url(#prefix__f)"
              style={{
                mixBlendMode: 'multiply',
              }}
            />
            <path
              d="M10.86.089V.041L0 12l10.86 11.958.5-.685v-5.386l-1.712-1.9 1.712-2.055V8.793l-.458-.657-.042.05v-.05l-4.177 4.6-.753-.829 5.43-6.163V.764l-.458-.723-.042.048z"
              stroke="#D3BA85"
              strokeWidth={0.031}
              strokeMiterlimit={10}
            />
          </g>
          <g
            style={{
              mixBlendMode: 'multiply',
            }}
          >
            <path
              d="M23.362 11.998L12.504.04l-.542.618v22.624l.542.675 3.442-4.143-.031-5.685 2.172-2.391 2.762 2.897.062-.064.058.064 2.393-2.637zm-7.71-2.212l.009-1.498.708.745-.716.753z"
              fill="url(#prefix__g)"
              style={{
                mixBlendMode: 'multiply',
              }}
            />
            <path
              d="M23.362 11.998L12.504.04l-.542.618v22.624l.542.675 3.442-4.143-.031-5.685 2.172-2.391 2.762 2.897.062-.064.058.064 2.393-2.637zm-7.71-2.212l.009-1.498.708.745-.716.753z"
              stroke="#D3BA85"
              strokeWidth={0.031}
              strokeMiterlimit={10}
            />
          </g>
        </g>
        <path
          d="M10.86.089V.041L0 12l10.86 11.958.5-.685v-5.386l-1.712-1.9 1.712-2.055V8.793l-.458-.657-.042.05v-.05l-4.177 4.6-.753-.829 5.43-6.163V.764l-.458-.723-.042.048zm12.502 11.909L12.504.04l-.542.618v22.624l.542.675 3.442-4.143-.031-5.685 2.172-2.391 2.762 2.897.062-.064.058.064 2.393-2.637zm-7.71-2.212l.009-1.498.708.745-.716.753z"
          fill="#EEA360"
          style={{
            mixBlendMode: 'multiply',
          }}
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__b"
          x1={5.93}
          y1={0.04}
          x2={5.93}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD9A6F" />
          <stop offset={0.18} stopColor="#917944" />
          <stop offset={0.47} stopColor="#E0C884" />
          <stop offset={0.59} stopColor="#372314" />
          <stop offset={0.85} stopColor="#B0965B" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__c"
          x1={5.43}
          y1={0.041}
          x2={5.43}
          y2={23.957}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__d"
          x1={5242.75}
          y1={769.865}
          x2={5242.75}
          y2={14495.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AD9A6F" />
          <stop offset={0.18} stopColor="#917944" />
          <stop offset={0.47} stopColor="#E0C884" />
          <stop offset={0.59} stopColor="#372314" />
          <stop offset={0.85} stopColor="#B0965B" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__e"
          x1={5065.36}
          y1={769.904}
          x2={5065.36}
          y2={14496.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__f"
          x1={1554.32}
          y1={23.24}
          x2={1554.32}
          y2={13749.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <linearGradient
          id="prefix__g"
          x1={5241.8}
          y1={769.904}
          x2={5241.8}
          y2={14496.2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDAF5F" />
          <stop offset={0.24} stopColor="#E7D2A2" />
          <stop offset={0.54} stopColor="#D9C087" />
          <stop offset={0.88} stopColor="#B59133" />
        </linearGradient>
        <clipPath id="prefix__a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
};

const FinawardsIcon = ({ name, ...props }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {iconNames[name]}
  </svg>
);

FinawardsIcon.propTypes = {
  name: PropTypes.oneOf(['original', 'gold', 'silver', 'bronze']).isRequired,
};

export default FinawardsIcon;
