import styled from 'styled-components';

import { mediaQueryDesktopMin } from 'styleUtils';

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 5.3px;
  position: relative;
  width: 13.33px;

  &:after {
    content: '';
    position: absolute;
    top: -16px;
    bottom: -16px;
    left: -16px;
    right: -16px;
    z-index: 1;
  }

  @media ${mediaQueryDesktopMin} {
    &:after {
      top: -8px;
      bottom: -8px;
      left: -8px;
      right: -8px;
    }
  }
`;

export default Wrapper;
