/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

const InnerHTML = memo(({ html, ...props }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
      className="InnerHTML"
    />
  );
});

InnerHTML.propTypes = {
  html: PropTypes.string,
};

InnerHTML.defaultProps = {
  html: '',
};

export default InnerHTML;
