import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SeoTextsBlocks from 'components/SeoTextsBlocks';
import SeoLinksBlocks from 'components/SeoLinksBlocks';
import PageBreadCrumbs from 'components/PageBreadCrumbs';
import { Footer } from 'components/FooterStyles';

import { BreadCrumbsWrapper } from './styles';

function PageFooter({ isShowTextBlocks, isShowBreadcrumbs }) {
  return (
    <Footer>
      {isShowTextBlocks && <SeoTextsBlocks />}

      <SeoLinksBlocks />

      {isShowBreadcrumbs && (
        <BreadCrumbsWrapper>
          <PageBreadCrumbs />
        </BreadCrumbsWrapper>
      )}
    </Footer>
  );
}

PageFooter.propTypes = {
  isShowBreadcrumbs: PropTypes.bool,
  isShowTextBlocks: PropTypes.bool,
};

PageFooter.defaultProps = {
  isShowBreadcrumbs: true,
  isShowTextBlocks: false,
};

export default memo(PageFooter);
