import styled from 'styled-components';

import { mediaQueryDesktopMin } from 'styleUtils';

const Wrapper = styled.aside`
  width: 100%;

  @media ${mediaQueryDesktopMin} {
    width: 300px;
    position: sticky;
    top: 16px;
  }
`;

export default Wrapper;
