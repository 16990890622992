import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

export const useSelectorProps = () => {
  const location = useLocation();
  const params = useParams();
  const intl = useIntl();

  const selectorProps = useMemo(() => ({ location, params, intl }), [
    location,
    params,
    intl,
  ]);

  return selectorProps;
};

export default useSelectorProps;
