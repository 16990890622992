import styled from 'styled-components';

import { mediaQueryDesktopMin } from 'styleUtils';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  @media ${mediaQueryDesktopMin} {
    max-width: 1292px;
    justify-content: center;
  }
`;

export default Wrapper;
