import React, { memo, useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  SectionMenuWrapper,
  SectionMenuStyled,
  Line,
  SectionMenuItemStyled,
  SlideIcon,
  PrevSlideBtn,
  NextSlideBtn,
} from './styles';

function SectionMenu({ menuItems, setMenu, visibleScroll }) {
  const refActive = useRef();
  const navRef = useRef();

  const [isHiddenNext, setHideNext] = useState(false);
  const [isHiddenPrev, setHidePrev] = useState(true);

  const handleSlide = useCallback(value => {
    return navRef?.current?.scrollBy({
      top: 0,
      left: value,
      behavior: 'smooth',
    });
  }, []);

  const hideNavigation = useCallback(() => {
    if (navRef?.current?.scrollLeft === 0) {
      setHidePrev(true);
    } else {
      setHidePrev(false);
    }
    if (
      navRef?.current?.offsetWidth + navRef?.current?.scrollLeft ===
      navRef?.current?.scrollWidth
    ) {
      setHideNext(true);
    } else {
      setHideNext(false);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    navRef?.current?.addEventListener('scroll', hideNavigation);

    return () => navRef?.current?.removeEventListener('scroll', hideNavigation);
  }, []);

  useEffect(
    () =>
      refActive?.current.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'center',
      }),
    [menuItems]
  );

  return (
    <SectionMenuWrapper>
      <PrevSlideBtn onClick={() => handleSlide(-150)} isHidden={isHiddenPrev}>
        <SlideIcon name="prevSlide" />
      </PrevSlideBtn>

      <NextSlideBtn onClick={() => handleSlide(+150)} isHidden={isHiddenNext}>
        <SlideIcon name="nextSlide" />
      </NextSlideBtn>

      <SectionMenuStyled ref={navRef} $visibleScroll={visibleScroll}>
        <Line />

        {menuItems.map(menuItem => (
          <SectionMenuItemStyled
            key={menuItem.name}
            isActive={menuItem.isActive}
            {...(menuItem.isActive ? { ref: refActive } : {})}
            onClick={() => setMenu(menuItem.name)}
          >
            {menuItem.name}
          </SectionMenuItemStyled>
        ))}
      </SectionMenuStyled>
    </SectionMenuWrapper>
  );
}

SectionMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  visibleScroll: PropTypes.bool,
};

SectionMenu.defaultProps = {
  visibleScroll: false,
};

export default memo(SectionMenu);
