import styled from 'styled-components';

import { colorGrey900 } from 'styleUtils';

export const Nav = styled.nav.attrs({ role: 'navigation' })``;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  font-size: 11px;
  color: #7788b0;
`;

export const ListItem = styled.li`
  &:not(:last-child)::after {
    content: '/';
    padding: 1ch;
  }

  &:last-child {
    scroll-snap-align: end;
  }
`;

export const Link = styled.a`
  color: currentColor;
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CurrentPage = styled.span`
  color: ${colorGrey900};
  white-space: nowrap;

  &::first-letter {
    text-transform: uppercase;
  }
`;
