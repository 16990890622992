import styled, { keyframes } from 'styled-components';

export const BgAnimation = keyframes`
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
`;

const Wrapper = styled.span`
  animation: ${BgAnimation} 2s ease infinite;
  background: linear-gradient(45deg, #e8ebee, #f8fafc, #e8ebee);
  background-size: 400% 400%;
  display: inline-flex;
  height: 100%;
  width: 100%;
`;

export default Wrapper;
