import React, { memo, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { getBenefits, getMoreInfo, getMenu } from 'utils';

import DropdownButton from 'components/DropdownButton';
import CardItemMoreInfo from 'components/CardItemMoreInfo';
import Hint from 'components/Hint';
import ProductActions from 'components/ProductActions';

import {
  ProductsInfo,
  ProductTitle,
  ProductHead,
  ProductClientTypes,
  ProductClientType,
  ProductMainInfo,
  ProductMainInfoBlock,
  ProductMainInfoBlockTitle,
  ProductMainInfoBlockTitleText,
  ProductMainInfoBlockText,
  ProductMainInfoBenefitsBlock,
  ProductMainInfoBenefit,
  MoreInfoFooterWrapper,
  MoreInfoButtonWrapper,
} from './styles';

function BankCardItem({ card }) {
  const { formatMessage } = useIntl();

  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);
  const [menuItems, setMenuItems] = useState(getMenu(formatMessage));

  const handleShowMoreInfo = useCallback(
    () => setIsShowMoreInfo(!isShowMoreInfo),
    [isShowMoreInfo]
  );

  const handleSetMenuItems = useCallback(
    currItem => {
      const newMenu = menuItems.map(el => ({
        ...el,
        isActive: el.name === currItem,
      }));

      setMenuItems(newMenu);
    },
    [menuItems]
  );

  const moreInfo = getMoreInfo(formatMessage);

  const benefits = getBenefits(formatMessage, card);

  return (
    <ProductsInfo>
      <ProductHead>
        <ProductTitle>{card.productName}</ProductTitle>

        <ProductClientTypes>
          {!!card.productClientType.length &&
            card.productClientType.map(clientType => (
              <ProductClientType key={clientType}>
                {formatMessage({
                  id: `BankCardItem.productClientType.${clientType.toLowerCase()}`,
                })}
              </ProductClientType>
            ))}
        </ProductClientTypes>
      </ProductHead>

      <ProductMainInfo>
        {moreInfo.map(block => (
          <>
            {block.name === 'cashDeposit' &&
            !card.proceedsEnrollmentCashboxAvailable ? null : (
              <ProductMainInfoBlock key={block.name}>
                <ProductMainInfoBlockTitle>
                  <ProductMainInfoBlockTitleText>
                    {formatMessage({
                      id: `BankCardItem.moreInfoBlock.${block.name}`,
                    })}
                  </ProductMainInfoBlockTitleText>
                  {!!block.hint(card) && (
                    <Hint position="bottom">
                      <span>{block.hint(card)}</span>
                    </Hint>
                  )}
                </ProductMainInfoBlockTitle>

                <ProductMainInfoBlockText>
                  {block.getValue(card)}
                </ProductMainInfoBlockText>
              </ProductMainInfoBlock>
            )}
          </>
        ))}
      </ProductMainInfo>

      {card?.advantages.length > 0 && (
        <ProductActions advantages={card.advantages} />
      )}

      <MoreInfoFooterWrapper>
        {!!benefits.length && (
          <ProductMainInfoBenefitsBlock>
            {benefits.map(benefit => (
              <ProductMainInfoBenefit key={benefit}>
                {benefit}
              </ProductMainInfoBenefit>
            ))}
          </ProductMainInfoBenefitsBlock>
        )}

        <MoreInfoButtonWrapper>
          <DropdownButton
            showMoreInfo={isShowMoreInfo}
            handleShowInfo={handleShowMoreInfo}
          >
            {formatMessage({ id: 'BankCardItem.moreInfoBlock.moreInfoBtn' })}
          </DropdownButton>
        </MoreInfoButtonWrapper>
      </MoreInfoFooterWrapper>

      <CardItemMoreInfo
        card={card}
        menuItems={menuItems}
        handleSetMenuItems={handleSetMenuItems}
        isShowMoreInfo={isShowMoreInfo}
      />
    </ProductsInfo>
  );
}

BankCardItem.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default memo(BankCardItem);
