import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';

import Wrapper from './styles';

class Hint extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    children: PropTypes.node,
    iconFill: PropTypes.string,
    position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']).isRequired,
    icon: PropTypes.string,
    tooltipProps: PropTypes.objectOf(PropTypes.any),
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    iconFill: 'gray',
    children: null,
    icon: 'questionMark',
    tooltipProps: {},
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    showTooltip: false,
  };

  showEvents = ['mouseenter', 'touchend'];

  hideEvents = ['mouseleave'];

  toggleTooltip = (isShow = null) => {
    this.setState(({ showTooltip }) => ({
      showTooltip: isShow !== null ? isShow : !showTooltip,
    }));
  };

  handleClickWrapper = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { position, children, iconFill, icon, tooltipProps } = this.props;

    const { showTooltip } = this.state;

    return (
      <Wrapper onClick={this.handleClickWrapper} data-tip-target>
        <Icon name={icon} fill={iconFill} tabIndex="0" />

        <Tooltip
          show={showTooltip}
          position={position}
          toggle={this.toggleTooltip}
          showEvents={this.showEvents}
          hideEvents={this.hideEvents}
          {...tooltipProps}
        >
          {children}
        </Tooltip>
      </Wrapper>
    );
  }
}

export default Hint;
