import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ContentPlaceholder from 'components/ContentPlaceholder';

import {
  BankCardListWrapper,
  ListWrapper,
  MoreCardsButtonWrapper,
  MoreCardsButton,
  CompanyInfoWrapper,
  CompanyLogoWrapper,
  CompanyData,
  CompanyDataTitle,
  CompanyDataMore,
  ProductsInfoWrapper,
  ProductHead,
  ProductMainInfo,
  ProductMainInfoBlock,
  MoreInfoFooterWrapper,
  ProductMainInfoBenefitsBlock,
  ProductMainInfoBenefit,
  MoreInfoButtonWrapper,
} from './styles';

function BankCardListLoader({ hideCompanyDetails }) {
  return (
    <BankCardListWrapper>
      <ListWrapper>
        {!hideCompanyDetails && (
          <CompanyInfoWrapper>
            <CompanyLogoWrapper>
              <ContentPlaceholder />
            </CompanyLogoWrapper>

            <CompanyData>
              <CompanyDataTitle>
                <ContentPlaceholder />
              </CompanyDataTitle>

              <CompanyDataMore>
                <ContentPlaceholder />
              </CompanyDataMore>
            </CompanyData>
          </CompanyInfoWrapper>
        )}

        <ProductsInfoWrapper>
          <ProductHead>
            <ContentPlaceholder />
          </ProductHead>

          <ProductMainInfo>
            {Array.from(Array(4)).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ProductMainInfoBlock key={index}>
                <ContentPlaceholder />
              </ProductMainInfoBlock>
            ))}
          </ProductMainInfo>

          <MoreInfoFooterWrapper>
            <ProductMainInfoBenefitsBlock>
              {Array.from(Array(2)).map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ProductMainInfoBenefit key={index}>
                  <ContentPlaceholder />
                </ProductMainInfoBenefit>
              ))}
            </ProductMainInfoBenefitsBlock>

            <MoreInfoButtonWrapper>
              <ContentPlaceholder />
            </MoreInfoButtonWrapper>
          </MoreInfoFooterWrapper>
        </ProductsInfoWrapper>
      </ListWrapper>

      {!hideCompanyDetails && (
        <MoreCardsButtonWrapper>
          <MoreCardsButton>
            <ContentPlaceholder />
          </MoreCardsButton>
        </MoreCardsButtonWrapper>
      )}
    </BankCardListWrapper>
  );
}

BankCardListLoader.propTypes = {
  hideCompanyDetails: PropTypes.bool,
};

BankCardListLoader.defaultProps = {
  hideCompanyDetails: false,
};

export default memo(BankCardListLoader);
