import Icon from 'components/Icon';

import styled from 'styled-components';
import {
  colorWhite,
  colorGrey100,
  colorGreen400,
  colorBlack900,
  fontFamilyRaleway,
} from 'styleUtils';

export const SectionMenuWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colorGrey100};
  position: absolute;
  bottom: 0px;
`;

export const SectionMenuStyled = styled.ul`
  overflow-x: auto;
  padding: 0;
  white-space: nowrap;
  width: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({ $visibleScroll }) => {
    if ($visibleScroll) {
      return `
        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          max-height: 50px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: ${colorGrey100};
        }
      `;
    }

    return `
      scrollbar-width: none;
      -ms-overflow-style: none;
    
      &::-webkit-scrollbar {
        display: none;
      }
    `;
  }};
`;

export const SectionMenuItemStyled = styled.li`
  list-style: none;
  display: inline-block;
  user-select: none;
  padding: 12px 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font: bold 12px/16px ${fontFamilyRaleway};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colorBlack900};
  position: relative;
  z-index: 50;

  ${({ isActive }) =>
    isActive &&
    `cursor: auto;
    border-bottom: 2px solid ${colorGreen400};
    color: ${colorGreen400};`};

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-bottom: 2px solid ${colorGreen400};
    color: ${colorGreen400};
  }
`;

export const SlideIcon = styled(Icon)`
  width: 9px;
`;

export const PrevSlideBtn = styled.a`
  width: 22px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
  background: linear-gradient(
    90deg,
    ${colorWhite} 0%,
    ${colorWhite} 67.51%,
    rgba(255, 255, 255, 0) 127.08%
  );

  ${({ isHidden }) => isHidden && `display: none;`}
`;

export const NextSlideBtn = styled(PrevSlideBtn)`
  justify-content: flex-end;
  right: 0;
  left: auto;
  background: linear-gradient(
    270deg,
    ${colorWhite} 0%,
    ${colorWhite} 67.51%,
    rgba(255, 255, 255, 0) 127.08%
  );
`;
