import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { parse, parseUrl, stringify } from 'query-string';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export const withPreparedLink = WrappedComponent => {
  class WithPreparedLink extends PureComponent {
    getSearch = nextSearch => {
      const {
        isMinTemplate,
        isAppTemplate,
        isHideBonus,
        isDrawPage,
        isBonusPage,
      } = this.context;

      const draw = isDrawPage ? 'draw' : undefined;
      const bonus = isBonusPage ? 'bonus' : undefined;

      return stringify({
        ...nextSearch,
        'min-template': isMinTemplate || undefined,
        'app-template': isAppTemplate || undefined,
        'hide-bonus-button': isHideBonus || undefined,
        'bonus-type': isHideBonus ? draw : nextSearch['bonus-type'],
        'deposit-type': isHideBonus
          ? Array.from(
              new Set([...(nextSearch['deposit-type'] || '').split(','), bonus])
            )
              .filter(type => !!type)
              .join(',')
          : nextSearch['deposit-type'],
      });
    };

    getLink = to => {
      if (!to) {
        return null;
      }

      if (typeof to === 'object' && to !== null) {
        const queries = this.getSearch(parse(to.search));

        return {
          ...to,
          search: `${queries ? '?' : ''}${queries}`,
        };
      }

      const parsedUrl = parseUrl(to);
      const queries = this.getSearch(parsedUrl.query);
      const search = `${queries ? '?' : ''}${queries}`;

      return `${parsedUrl.url}${search}`;
    };

    render() {
      const { to, ...props } = this.props;

      const preparedLink = this.getLink(to);

      return <WrappedComponent preparedLink={preparedLink} {...props} />;
    }
  }

  WithPreparedLink.propTypes = {
    to: PropTypes.oneOfType([
      PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.objectOf(PropTypes.any),
      }),
      PropTypes.string,
    ]),
  };

  WithPreparedLink.defaultProps = {
    to: null,
  };

  WithPreparedLink.displayName = `WithPreparedLink(${getDisplayName(
    WrappedComponent
  )})`;

  hoistNonReactStatics(WithPreparedLink, WrappedComponent);

  return WithPreparedLink;
};

export default withPreparedLink;
