import styled from 'styled-components';

import {
  colorBlack900,
  colorBlack300,
  colorBlack200,
  colorWhite2,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
  colorGrey50,
} from 'styleUtils';

export const ProductsInfo = styled.div`
  width: 100%;
`;

export const ProductHead = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 4px;
  width: 100%;

  margin: 0 0 24px 0;
`;

export const ProductTitle = styled.div`
  color: ${colorBlack900};
  font-family: ${fontFamilyRaleway};
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor: default;

  margin: 0 16px 0 0;
`;

export const ProductClientTypes = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProductClientType = styled.div`
  font-family: ${fontFamilyRaleway};
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colorBlack200};
  padding: 5px 8px;
  cursor: default;

  background: ${colorWhite2};
  border-radius: 10px;

  &:not(:last-child) {
    margin: 0 4px 0 0;
  }
`;

export const ProductMainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    flex-wrap: nowrap;
  }
`;

export const ProductMainInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 0 24px 0;

  @media ${mediaQueryMobileLargeMin} {
    width: 25%;
  }
`;

export const ProductMainInfoBlockTitle = styled.div`
  display: flex;
  align-items: center;

  font-family: ${fontFamilyRoboto};
  font-size: 14px;
  line-height: 16px;

  color: ${colorBlack200};
`;

export const ProductMainInfoBlockTitleText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;

export const ProductMainInfoBlockText = styled.div`
  font-family: ${fontFamilyRoboto};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  margin: 8px 0 0 0;
  padding: 0 15px 0 0;
  white-space: break-spaces;
  cursor: default;

  color: ${colorBlack900};

  display: flex;
  flex-direction: column;

  @media ${mediaQueryDesktopMin} {
    line-height: 24px;
  }
`;

export const ProductMainInfoBenefitsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: -4px;
  width: 100%;
`;

export const ProductMainInfoBenefit = styled.div`
  width: min-content;
  font-family: ${fontFamilyRoboto};
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.01em;
  color: ${colorBlack300};
  padding: 6px 12px;
  margin: 4px;
  height: min-content;
  cursor: default;

  background: ${colorWhite2};
  border-radius: 20px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MoreInfoFooterWrapper = styled.div`
  display: flex;
  margin: 0 -16px;
  padding: 16px;
  border-top: 1px solid ${colorGrey50};
  flex-direction: column;
  justify-content: space-between;

  @media ${mediaQueryMobileLargeMin} {
    flex-direction: row;
  }
`;

export const MoreInfoButtonWrapper = styled.div`
  padding: 0 0 0 4px;
  margin-top: 24px;
  white-space: nowrap;

  @media ${mediaQueryMobileLargeMin} {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
  }

  @media ${mediaQueryDesktopMin} {
    margin-bottom: auto;
  }
`;
