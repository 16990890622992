import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import SectionMenu from 'components/SectionMenu';

import {
  MoreInfoWrapper,
  MoreInfoTitle,
  MoreInfoBlock,
  MoreInfoItem,
  MoreInfoItemTitle,
  MoreInfoItemContent,
  MoreInfoItemContentList,
  MoreInfoItemContentItem,
} from './styles';

function CardItemMoreInfo({
  card,
  menuItems,
  handleSetMenuItems,
  isShowMoreInfo,
}) {
  const { formatMessage } = useIntl();

  const activeItem = useMemo(() => menuItems.find(el => el.isActive), [
    menuItems,
  ]);

  const activeItemParams = useMemo(() => activeItem.getParams({ ...card }), [
    activeItem,
  ]);

  const showList = useCallback(
    param =>
      (!!activeItemParams[param].length && (
        <MoreInfoItemContentList>
          {activeItemParams[param].map((el, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MoreInfoItemContentItem key={index}>{el}</MoreInfoItemContentItem>
          ))}
        </MoreInfoItemContentList>
      )) ||
      '-',
    [activeItemParams]
  );

  return (
    <MoreInfoWrapper isShowMoreInfo={isShowMoreInfo}>
      <SectionMenu menuItems={menuItems} setMenu={handleSetMenuItems} />

      <MoreInfoTitle>{activeItem.name}</MoreInfoTitle>

      <MoreInfoBlock>
        {Object.keys(activeItemParams).map(param => (
          <MoreInfoItem key={param}>
            <MoreInfoItemTitle>
              {formatMessage({ id: `MenuItems.params.title.${param}` })}
            </MoreInfoItemTitle>
            <MoreInfoItemContent>
              {Array.isArray(activeItemParams[param])
                ? showList(param)
                : activeItemParams[param]}
            </MoreInfoItemContent>
          </MoreInfoItem>
        ))}
      </MoreInfoBlock>
    </MoreInfoWrapper>
  );
}

CardItemMoreInfo.propTypes = {
  card: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSetMenuItems: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  isShowMoreInfo: PropTypes.bool.isRequired,
};

export default memo(CardItemMoreInfo);
