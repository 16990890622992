import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import { Wrapper, Title, Description, Handler, IconWrapper } from './styles';

function NoData({ isRequestField, resetFilters, fetchData }) {
  const buttonHandler = useCallback(() => {
    if (isRequestField) {
      fetchData();
    } else {
      resetFilters();
    }
  }, [isRequestField]);

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name={isRequestField ? 'requestFailed' : 'bigFilters'} />
      </IconWrapper>

      <Title>
        <FormattedMessage
          id={isRequestField ? 'somethingWentWrong' : 'nothingFound'}
        />
      </Title>

      <Description>
        <FormattedMessage
          id={
            isRequestField
              ? 'thereWereProblems'
              : 'nothingFound.specifiedCriteria'
          }
        />
      </Description>

      <Handler onClick={buttonHandler}>
        <FormattedMessage id={isRequestField ? 'tryAgain' : 'resetFilters'} />
      </Handler>
    </Wrapper>
  );
}

NoData.propTypes = {
  isRequestField: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
};

export default memo(NoData);
