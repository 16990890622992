import React, { memo } from 'react';

import Banner from 'components/Banner';

import Wrapper from './styles';

const PageSidebar = memo(() => {
  return (
    <Wrapper id="mc-sidebar">
      <Banner id="hp-react-banner-premium" slot="bn-slot-premium" />
      <Banner id="hp-react-banner-sticky" slot="bn-slot-sticky" isSticky />
    </Wrapper>
  );
});

export default PageSidebar;
