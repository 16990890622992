import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentSection from 'components/ContentSection';

function Banner({ id, slot, isSticky }) {
  const location = useLocation();

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent('bn-slot-display', { detail: { 'bn-slots': [slot] } })
    );
  }, [location.pathname]);

  return (
    <ContentSection style={{ padding: 0 }} sticky={isSticky}>
      <div id={id} data-bn-slot={slot} />
    </ContentSection>
  );
}

Banner.propTypes = {
  id: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  isSticky: PropTypes.bool,
};

Banner.defaultProps = {
  isSticky: false,
};

export default memo(Banner);
