import styled from 'styled-components';

import {
  colorBlack900,
  colorBlack200,
  colorGrey50,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileLargeMin,
  colorGreen400,
} from 'styleUtils';

export const MoreInfoWrapper = styled.div`
  display: ${({ isShowMoreInfo }) => (isShowMoreInfo ? 'block' : 'none')};
  width: 100%;
`;

export const MoreInfoTitle = styled.h2`
  width: 100%;
  margin: 16px 0;
  font-family: ${fontFamilyRaleway};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  cursor: default;

  color: ${colorBlack900};
`;

export const MoreInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MoreInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid ${colorGrey50};
  padding: 16px 0;
`;

export const MoreInfoItemTitle = styled.div`
  width: 40%;
  font-family: ${fontFamilyRoboto};
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  cursor: default;

  color: ${colorBlack200};

  @media ${mediaQueryMobileLargeMin} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const MoreInfoItemContent = styled.div`
  width: 60%;
  font-family: ${fontFamilyRoboto};
  font-size: 12px;
  line-height: 18px;
  padding: 0 0 0 10px;
  text-align: end;
  cursor: default;

  color: ${colorBlack900};

  & a,
  a:visited {
    color: ${colorGreen400};
  }

  @media ${mediaQueryMobileLargeMin} {
    text-align: start;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const MoreInfoItemContentList = styled.ul``;

export const MoreInfoItemContentItem = styled.li`
  list-style-type: disc;
  list-style-position: inside;
`;
