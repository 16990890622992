import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import React, { useMemo, memo } from 'react';

import { Nav, List, ListItem, Link, CurrentPage } from './styles';

const last = list => list[list.length - 1];
const withoutLast = list => list.slice(0, -1);

const getFullURL = path =>
  `${process.env.APP_MINFIN_DOMAIN}${path}${!path.endsWith('/') ? '/' : ''}`;

function BreadCrumbs({ breadcrumbs }) {
  const { name: currentPageName } = last(breadcrumbs);

  const helmetScript = useMemo(
    () => [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: breadcrumbs.map(({ name, link }, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@id': getFullURL(link),
              name,
            },
          })),
        }),
      },
    ],
    [breadcrumbs]
  );

  return (
    <Nav>
      <Helmet script={helmetScript} />

      <List>
        {withoutLast(breadcrumbs).map(({ link, name }) => (
          <ListItem key={link}>
            <Link href={getFullURL(link)}>{name}</Link>
          </ListItem>
        ))}

        <ListItem>
          <CurrentPage aria-current="page">{currentPageName}</CurrentPage>
        </ListItem>
      </List>
    </Nav>
  );
}

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
};

BreadCrumbs.defaultProps = {
  breadcrumbs: [],
};

export default memo(BreadCrumbs);
