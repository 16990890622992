import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ContentSection from 'components/ContentSection';
import ContentSectionTitle from 'components/ContentSectionTitle';

import { LinksList, LinksListItem, Link } from './styles';

function SeoLinksBlock({ title, links }) {
  return (
    <ContentSection>
      <ContentSectionTitle>{title}</ContentSectionTitle>

      <LinksList>
        {links.map(link => (
          <LinksListItem key={link.id}>
            <Link href={link.url} title={link.name}>
              {link.name}
            </Link>
          </LinksListItem>
        ))}
      </LinksList>
    </ContentSection>
  );
}

SeoLinksBlock.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(SeoLinksBlock);
