import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useSelectorProps } from './useSelectorProps';
import { useSeoMeta } from './useSeoMeta';

export const useWebPageSchema = (
  mainEntity = {},
  metaTitleOtherPage = '',
  pageDescriptionOtherPage = ''
) => {
  const { locale, formatMessage } = useIntl();
  const {
    location: { pathname },
  } = useSelectorProps();
  const {
    pageTitle,
    metaDescription,
    metaTitle,
    pageDescription,
  } = useSeoMeta();

  return useMemo(
    () =>
      JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: metaTitleOtherPage || metaTitle,
        dateModified: new Date().toISOString(),
        description: pageDescriptionOtherPage || metaDescription,
        publisher: {
          '@type': 'Organization',
          name: 'Minfin.com.ua',
          knowsLanguage: ['uk-UA', 'ru-UA'],
          description: formatMessage({
            id: 'pageSchema.publisher.description',
          }),
          url: `${process.env.APP_MINFIN_DOMAIN}/`,
          logo: {
            '@type': 'ImageObject',
            url: `${process.env.APP_MINFIN_DOMAIN}/i/redesign/minfin-logo-ru.png`,
          },
          foundingDate: '2008',
          sameAs: [
            'https://www.facebook.com/minfin.com.ua/',
            'https://t.me/Minfin_com_ua/',
            'https://www.youtube.com/channel/UCEW1QlFX_ito4gd3QXxyt5Q/',
            'https://twitter.com/MinfinComUa/',
            'https://www.instagram.com/minfin.com.ua/',
          ],
          address: {
            '@type': 'PostalAddress',
            addressCountry: {
              '@type': 'Country',
              name: formatMessage({
                id: 'pageSchema.addressCountry.name',
              }),
            },
            addressLocality: formatMessage({
              id: 'pageSchema.addressLocality',
            }),
            postalCode: '03038',
            streetAddress: formatMessage({
              id: 'pageSchema.streetAddress',
            }),
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+38(044)392-47-40',
              contactType: 'customer service',
              areaServed: 'UA',
              availableLanguage: ['Ukrainian', 'Russian'],
              email: 'info@minfin.com.ua',
            },
          ],
        },
        mainEntity,
      }),
    [
      metaDescription,
      pageDescription,
      metaTitle,
      pageTitle,
      locale,
      pathname,
      pageDescriptionOtherPage,
      metaTitleOtherPage,
      mainEntity,
    ]
  );
};

export default useWebPageSchema;
