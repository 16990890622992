import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getSeoMeta } from 'selectors';

import { META_FOR_RKO_PAGE } from 'constants/index';

import { useSelectorProps } from './useSelectorProps';

export const useSeoMeta = (meta, values) => {
  const { titleID, descriptionID, metaTitleID, metaDescriptionID } =
    meta || META_FOR_RKO_PAGE;

  const { formatMessage } = useIntl();
  const selectorProps = useSelectorProps();

  const {
    pageTitle,
    pageDescription,
    metaTitle,
    metaDescription,
  } = useSelector(state => getSeoMeta(state, selectorProps));

  const formattedPageTitle = useMemo(
    () =>
      pageTitle ||
      formatMessage(
        {
          id: titleID,
        },
        values
      ),
    [pageTitle, titleID, values]
  );

  const formattedPageDescription = useMemo(
    () =>
      pageDescription ||
      formatMessage(
        {
          id: descriptionID,
        },
        { ...values, br: <br /> }
      ),
    [pageTitle, titleID, values]
  );

  const formattedMetaTitle = useMemo(
    () =>
      metaTitle ||
      formatMessage(
        {
          id: metaTitleID,
        },
        values
      ),
    [metaTitle, metaTitleID, values]
  );

  const formattedMetaDescription = useMemo(
    () =>
      metaDescription ||
      formatMessage(
        {
          id: metaDescriptionID,
        },
        values
      ),
    [metaDescription, metaDescriptionID, values]
  );

  return {
    pageTitle: formattedPageTitle,
    pageDescription: formattedPageDescription,
    metaTitle: formattedMetaTitle,
    metaDescription: formattedMetaDescription,
  };
};

export default useSeoMeta;
