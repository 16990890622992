import styled from 'styled-components';

import { mediaQueryMobileMin } from 'styleUtils';

export const Arrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border: 5px solid transparent;
`;

export const Wrapper = styled.div`
  background: rgba(17, 21, 18, 0.9);
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  left: ${({ left }) => `${left}px`};
  min-width: ${({ width }) => (width ? `${width}px` : '265px')};
  opacity: 1;
  padding: 8px;
  position: fixed;
  top: ${({ top }) => `${top}px`};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  width: ${({ width }) => (width ? `${width}px` : '265px')};
  z-index: 1003;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);

  @media ${mediaQueryMobileMin} {
    min-width: ${({ width }) => (width ? `${width}px` : '265px')};
    width: ${({ width }) => (width ? `${width}px` : '265px')};
  }

  ${({ fixedPosition }) => {
    if (fixedPosition) {
      return `
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      `;
    }

    return '';
  }}

  ${Arrow} {
    ${({ position, arrowOffset }) => {
      if (position === 'top') {
        return `
            transform: translateX(-50%);
            top: auto;
            bottom: -5px;
            left: ${arrowOffset ? `${arrowOffset}%` : '50%'};
            border-bottom: none;
            border-top-color: rgba(0, 0, 0, 0.9);
          `;
      }

      if (position === 'bottom') {
        return `
            transform: translateX(-50%);
            top: -5px;
            bottom: auto;
            left: ${arrowOffset ? `${arrowOffset}%` : '50%'};
            border-top: none;
            border-bottom-color: rgba(0, 0, 0, 0.9);
          `;
      }

      if (position === 'left') {
        return `
            transform: translateY(-50%);
            right: -5px;
            left: auto;
            top: ${arrowOffset ? `${arrowOffset}%` : '50%'};
            border-right: none;
            border-left-color: rgba(0, 0, 0, 0.9);
          `;
      }

      if (position === 'right') {
        return `
            transform: translateY(-50%);
            left: -5px;
            right: auto;
            top: ${arrowOffset ? `${arrowOffset}%` : '50%'};
            border-left: none;
            border-right-color: rgba(0, 0, 0, 0.9);
          `;
      }

      return '';
    }}
  }
`;
