import styled from 'styled-components';

import { fontFamilyRaleway, colorRed, colorBlue } from 'styleUtils';

export const Title = styled.h3`
  font-family: ${fontFamilyRaleway};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const LinksList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const LinksListItem = styled.li`
  margin: 0;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Link = styled.a`
  color: ${colorBlue};
  font-size: 14px;
  line-height: 18px;
  transition: color 0.3s;

  &:hover,
  &:active {
    color: ${colorRed};
  }
`;
