import styled from 'styled-components';

import {
  fontFamilyRaleway,
  mediaQueryMobileLargeMin,
  mediaQueryTabletMin,
} from 'styleUtils';

const Wrapper = styled.h2`
  align-items: center;
  display: flex;
  font-family: ${fontFamilyRaleway};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 12px;
  padding: 0 16px;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    padding: 0;
  }

  @media ${mediaQueryTabletMin} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export default Wrapper;
