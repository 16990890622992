import styled from 'styled-components';

import {
  colorWhite,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const BankCardListWrapper = styled.div`
  width: 100%;

  & :not(:last-child) {
    margin: 0 0 8px 0;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 16px 0 16px;
  background: ${colorWhite};
`;

export const MoreCardsButtonWrapper = styled.div`
  width: 100%;
  margin: 1px 0 0 0;
  padding: 16px 0;
  background: ${colorWhite};
  text-align: center;
`;

export const MoreCardsButton = styled.div`
  display: inline-block;
  width: 120px;
  height: 16px;
`;

export const CompanyInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 24px 0;
`;

export const CompanyLogoWrapper = styled.div`
  flex: none;
  width: 32px;
  height: 32px;
`;

export const CompanyData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
`;

export const CompanyDataTitle = styled.div`
  width: 120px;
  height: 14px;

  @media ${mediaQueryMobileLargeMin} {
    width: 264px;
    height: 16px;
  }
`;

export const CompanyDataMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0 0 0;

  width: 184px;
  height: 12px;

  @media ${mediaQueryMobileLargeMin} {
    width: 192px;
  }
`;

export const ProductsInfoWrapper = styled.div`
  width: 100%;
`;

export const ProductHead = styled.div`
  width: 200px;
  height: 20px;

  margin: 0 0 24px 0;

  @media ${mediaQueryMobileLargeMin} {
    width: 264px;
  }
`;

export const ProductMainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${mediaQueryMobileLargeMin} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const ProductMainInfoBlock = styled.div`
  width: 40%;
  margin: 0 10% 24px 0;
  height: 40px;

  @media ${mediaQueryMobileLargeMin} {
    width: 20%;
    margin: 0 0 24px 0;
  }
`;

export const MoreInfoFooterWrapper = styled.div`
  display: flex;
  margin: 0 0 24px 0;
  flex-direction: column;
  justify-content: space-between;

  @media ${mediaQueryMobileLargeMin} {
    flex-direction: row;
  }
`;

export const ProductMainInfoBenefitsBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px;
  width: 100%;
`;

export const ProductMainInfoBenefit = styled.div`
  width: 200px;
  height: 24px;
  margin: 4px;

  @media ${mediaQueryMobileLargeMin} {
    width: 268px;
  }

  @media ${mediaQueryDesktopMin} {
    width: 200px;
  }
`;

export const MoreInfoButtonWrapper = styled.div`
  padding: 0 0 0 4px;
  margin-top: 24px;
  height: 16px;
  width: 200px;

  @media ${mediaQueryMobileLargeMin} {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;

    width: 164px;
    height: 24px;
  }

  @media ${mediaQueryDesktopMin} {
    margin-bottom: auto;
  }
`;
