import React from 'react';
import { useSelector } from 'react-redux';

import { makeGetSeoTextsBlocksItems } from 'selectors';
import { useSelectorProps } from 'hooks';

import InnerHTML from 'components/InnerHTML';

import { Wrapper, Blocks } from './styles';

const getSeoTextsBlocksItems = makeGetSeoTextsBlocksItems();

function SeoTextsBlocks() {
  const selectorProps = useSelectorProps();

  const seoTextsBlocksItems = useSelector(state =>
    getSeoTextsBlocksItems(state, selectorProps)
  );

  return seoTextsBlocksItems.length ? (
    <Wrapper>
      <Blocks>
        {seoTextsBlocksItems.map(({ id, description }) => (
          <InnerHTML key={id} html={description} />
        ))}
      </Blocks>
    </Wrapper>
  ) : null;
}

export default SeoTextsBlocks;
