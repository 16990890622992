import React, { memo } from 'react';
import { bool, func, node, string } from 'prop-types';

import { Button } from './styles';

function DropdownButton({ showMoreInfo, handleShowInfo, children, elementId }) {
  return (
    <Button
      data-active={showMoreInfo}
      data-elementid={elementId}
      onClick={handleShowInfo}
    >
      {children}
    </Button>
  );
}
DropdownButton.propTypes = {
  showMoreInfo: bool.isRequired,
  handleShowInfo: func.isRequired,
  children: node.isRequired,
  elementId: string,
};

DropdownButton.defaultProps = {
  elementId: null,
};

export default memo(DropdownButton);
