import styled from 'styled-components';

import { mediaQueryDesktopMin } from 'styleUtils';

const Wrapper = styled.main`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  @media ${mediaQueryDesktopMin} {
    padding-right: 24px;
    width: calc(100% - 300px);
  }
`;

export default Wrapper;
