import styled from 'styled-components';

import {
  colorBlack900,
  fontFamilyRaleway,
  fontFamilyRoboto,
  mediaQueryMobileMin,
  mediaQueryMobileLargeMin,
  mediaQueryDesktopMin,
} from 'styleUtils';

export const ListHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
  padding: 0 16px;
  z-index: 1;

  @media ${mediaQueryMobileMin} {
    padding: 0;
  }

  @media ${mediaQueryMobileLargeMin} {
    align-items: baseline;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const List = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const SorterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const SorterLabel = styled.p`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${colorBlack900};
  margin-right: 8px;
`;

export const TextCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 8px 0;

  @media ${mediaQueryDesktopMin} {
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
`;

export const TextCount = styled.p`
  font-family: ${fontFamilyRaleway};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${colorBlack900};

  @media ${mediaQueryMobileLargeMin} {
    font-size: 18px;
  }
`;
